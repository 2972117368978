body {
  background-color: #5e0100 !important;
}

path {
  fill-opacity: 0;
  stroke: white !important;
  stroke-width: 4 !important;
  stroke-dasharray: 62718;
  stroke-dashoffset: 62718;
  animation: draw 20s 1 linear;
  animation-fill-mode: forwards;
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
